import React, { Fragment, useEffect } from "react";
import Appointment from "../../components/Appointment/Appointment";
import Features from "../../components/Features/Features";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import ProjectSection from "../../components/ProjectSection/ProjectSection";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import Testimonial from "../../components/Testimonial/Testimonial";
import Logo from "../../images/logo.png";
import Hero2 from "../../components/hero2/hero2";
import About from "../../components/about/about";
import img from "../../images/ourvision.png";
import { useLocation } from "react-router-dom";
import StickyFooter from "../../components/StickyFooter";
import Hero3 from "../../components/hero3/hero3";
import Hero4 from "../../components/hero4/hero4";
import SectionTitle from "../../components/SectionTitle";

const HomePage = () => {
  let { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        // const id = hash.replace("#", "");
        // const element = document.getElementById(hash);
        let headerHeight = 120;
        if (hash.includes("contact")) {
          headerHeight = 70;
        }
        const topOfElement =
          document.querySelector(hash).offsetTop - headerHeight;
        window.scroll({ top: topOfElement, behavior: "smooth" });
        // now account for fixed header
        // if (element) {
        //   element.scrollIntoView({ behavior: "smooth" });
        // }
      }, 0);
    }
  }, [pathname, hash, key]);
  return (
    <Fragment>
      <Navbar topbarBlock={"wpo-header-style-2"} Logo={Logo} />
      <Hero2 />
      <div id="about">
        <About
          image={img}
          MainTitle="OUR VISION"
          title={
            <h3 style={{ color: "#006266" }}>
              Welcome to Ideal Bathroom Renovations!
            </h3>
          }
          description={
            <>
              <p>
                We are a company dedicated to transforming your outdated
                bathroom into a beautiful, functional, and modern space. Our
                team of experienced professionals offers a range of services
                including design consultation, demolition and removal of
                existing fixtures, installation of new fixtures such as toilets,
                tiling and more.
              </p>
              <p>
                At <b>Ideal Bathroom Renovations</b>, we pride ourselves on our
                attention to detail, quality craftsmanship, and excellent
                customer service. Contact us today to schedule your bathroom
                renovation and turn your dream bathroom into a reality.
              </p>
            </>
          }
        />
      </div>

      <Features fClass={"wpo-features-section-s2"} />
      <ServiceSection />
      <About
        image={img}
        title={
          <h3 style={{ color: "#006266", width: "100%" }}>
            There are several reasons why you may want to consider renovating
            your bathroom
          </h3>
        }
        description={
          <>
            <p style={{}}>
              <b>Improved functionality</b>: If your current bathroom doesn't
              meet your needs or lacks essential features, such as storage
              space, a larger shower, or a more efficient layout, a renovation
              can help you create a more functional and convenient space.
              <br />
              <br />
              <b>Increased home value</b>: A bathroom renovation can add value
              to your home and make it more appealing to potential buyers if
              you're planning to sell in the future. A modern, updated bathroom
              can be a major selling point for many homebuyers.
              <br />
              <br />
              <b>Improved energy efficiency</b>: If your bathroom fixtures are
              outdated, a renovation can help you upgrade to more
              energy-efficient options such as low-flow toilets, LED lighting,
              and water-saving showerheads. This can help you save money on
              utility bills and reduce your environmental impact.
              <br />
              <br />
              <b>Enhanced aesthetics</b>: A bathroom renovation can transform an
              outdated or worn-down bathroom into a beautiful, modern space.
              With updated fixtures, new tile, and fresh paint, you can create a
              space that reflects your personal style and makes you feel relaxed
              and refreshed.
              <br />
              <br />
              <b>Increased safety and accessibility</b>: If you or a family
              member has mobility issues, a bathroom renovation can include
              features such as grab bars, non-slip flooring, and walk-in tubs or
              showers that make the space safer and more accessible.
              <br />
              <br />
              Overall, a bathroom renovation can improve your quality of life,
              increase your home's value, and provide a space that you love and
              enjoy using every day.
            </p>
          </>
        }
      />

      <div id="service">
        <ProjectSection title="OUR IDEAL PACKAGES" />
        <ProjectSection title="TYPES OF LAUNDRIES" laundry />
        <div id="gallery">
          <ProjectSection title="GALLERY" gallery />
        </div>
        {/* <SectionTitle MainTitle={"GALLERY"} />
        <Hero4 />
        <div style={{ padding: 20 }} /> */}
      </div>

      <div id="contact">
        <Appointment />
      </div>
      <div id="testimonial">
        <Testimonial />
      </div>
      <Footer />
      <Scrollbar />
      <StickyFooter />
    </Fragment>
  );
};

export default HomePage;
