import React from "react";
import SectionTitle from "../SectionTitle";

const About = (props) => {
  return (
    <section className="wpo-about-section section-padding">
      <div className="container">
        {props.MainTitle && <SectionTitle MainTitle={props.MainTitle} />}
        <div className="wpo-about-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="wpo-about-img">
                <img src={props.image} alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="wpo-about-content">
                <div className="wpo-section-title-s2">{props.title}</div>
                <div className="wpo-about-content-inner">
                  {props.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
