import React, { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import cimg from "../../images/Sydney-Plumbing-Service-Area.jpg";
import emailjs from "@emailjs/browser";

const Appointment = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_3co2xwl",
        "template_id6357y",
        form.current,
        "ZQUArzSjK_kX-wmVj"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessageSent(true);
          submitHandler(e);
          setTimeout(() => {
            setMessageSent(false);
          }, 2000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  // const sendForm = () => {
  //   setMessageSent(true);
  // };
  const [messageSent, setMessageSent] = useState(false);
  const [forms, setForms] = useState({
    name: "",
    email: "",
    phone: "",
    suburb: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        suburb: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <section
      className="wpo-contact-section section-padding"
      style={{ padding: "50px 0px" }}
    >
      {window.innerWidth >= 768 ? (
        <div className="wpo-contact-img">
          <img src={cimg} alt="" style={{ objectFit: "fill" }} />
        </div>
      ) : null}
      <div className="container">
        <div className="wpo-contact-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-contact-form-area">
                <div className="wpo-section-title-s2">
                  <h2>FREE QUOTE FORM</h2>
                </div>
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="contact-validation-active"
                >
                  <div className="row">
                    <div className="col col-lg-6 col-12">
                      <div className="form-group">
                        <h4>
                          <label>Name*</label>
                        </h4>
                        <input
                          className="form-control"
                          value={forms.name}
                          type="text"
                          name="name"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Your Name"
                        />
                        {validator.message(
                          "name",
                          forms.name,
                          "required|alpha_space"
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-group">
                        <h4>
                          <label>Email*</label>
                        </h4>
                        <input
                          className="form-control"
                          value={forms.email}
                          type="email"
                          name="email"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Your Email"
                        />
                        {validator.message(
                          "email",
                          forms.email,
                          "required|email"
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-group">
                        <h4>
                          <label>Phone*</label>
                        </h4>
                        <input
                          className="form-control"
                          value={forms.phone}
                          type="phone"
                          name="phone"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Your phone"
                        />

                        {validator.message(
                          "phone",
                          forms.phone,
                          "required|phone"
                        )}
                      </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                      <div className="form-group">
                        <h4>
                          <label>Suburb*</label>
                        </h4>
                        <input
                          className="form-control"
                          value={forms.suburb}
                          name="suburb"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          placeholder="Your suburb"
                        />
                        {validator.message("suburb", forms.suburb, "required")}
                      </div>
                    </div>
                    <div className="col fullwidth col-lg-12 ">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                          value={forms.message}
                          type="text"
                          name="message"
                          placeholder="Message"
                        ></textarea>
                        {validator.message(
                          "message",
                          forms.message,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="submit-area">
                    <h4>
                      <button
                        disabled={messageSent}
                        type="submit"
                        onClick={sendEmail}
                        className="theme-btn disabled"
                        style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                      >
                        {messageSent ? "WE WILL BE IN TOUCH SOON" : "GET QUOTE"}
                      </button>
                    </h4>
                  </div>
                </form>

                <div className="border-style"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
