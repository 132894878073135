import bathroomPremium from "../images/projects/bathroom-premium.jpg";
import bathroomBasic from "../images/projects/bathroom-basic.jpg";
import bathroomIdeal from "../images/projects/bathroom-ideal.jpg";
import laundryFunctional from "../images/projects/laundry-functional.jpg";
import laundryIdeal from "../images/projects/laundry-ideal.jpg";
import sSingleimg1 from "../images/project-single/2.jpg";
import sSingleimg2 from "../images/project-single/3.jpg";
import gallery1 from "../images/slider/gallery1.jpg";
import gallery2 from "../images/slider/gallery2.jpg";
import gallery4 from "../images/slider/gallery4.jpg";
import gallery3 from "../images/slider/gallery3.jpg";
import gallery5 from "../images/slider/gallery5.jpg";
import gallery6 from "../images/slider/gallery6.jpg";

const Bathrooms = [
  {
    Id: "1",
    pImg: bathroomBasic,
    subTitle: "",
    title: "STANDARD",
    price: "$17,999",
    description: (
      <ul style={{ fontSize: 16 }}>
        <li>Floor-Mounted Vanity</li>
        <li>Chrome Taps</li>
        <li>Semi-Frameless Shower Screen</li>
        <li>Standard Tiles</li>
      </ul>
    ),
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },
  {
    Id: "2",
    pImg: bathroomPremium,
    subTitle: "",
    title: "PREMIUM",
    price: "$20,999",
    description: (
      <ul style={{ fontSize: 16 }}>
        <li>Freestanding Vanity</li>
        <li>Black or Brushed Nickel Taps</li>
        <li>Frameless Shower Screen</li>
        <li>Premium Tiles</li>
        <li>Niche</li>
        <li>Bathtub</li>
      </ul>
    ),
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },
  {
    Id: "3",
    pImg: bathroomIdeal,
    subTitle: "",
    price: "$23,999",
    title: "IDEAL",
    description: (
      <ul style={{ fontSize: 16 }}>
        <li>Freestanding Vanity</li>
        <li>Black, Brushed Nickel or Brass Taps</li>
        <li>Custom Shower Screen</li>
        <li>Premium Tiles, Subway or Herringbone</li>
        <li>Niche</li>
        <li>Bathtub</li>
        <li>Feature Wall</li>
        <li>Complimentary Fittings</li>
      </ul>
    ),
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },
];

const Laundries = [
  {
    Id: "1",
    pImg: laundryFunctional,
    subTitle: "",
    title: "FUNCTIONAL",
    description: "",
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },
  {
    Id: "2",
    pImg: laundryIdeal,
    subTitle: "",
    title: "IDEAL",
    description: "",
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },
];
const Gallery = [
  {
    Id: "1",
    pImg: gallery1,
    description: "CarlingFord, NSW",
    title: "",
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },
  {
    Id: "2",
    pImg: gallery2,
    description: "Menai, NSW",
    title: "",
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },

  {
    Id: "2",
    pImg: gallery6,
    description: "Artarmon, NSW",
    title: "",
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },
  {
    Id: "2",
    pImg: gallery4,
    description: "Rosebery, NSW",
    title: "",
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },
  {
    Id: "2",
    pImg: gallery5,
    description: "Rydalmere, NSW",
    title: "",
    spImg1: sSingleimg1,
    spImg2: sSingleimg2,
  },
];
export default Bathrooms;
export { Laundries, Gallery };
