import React from "react";
import { Link } from "react-router-dom";

import MobileMenu from "../MobileMenu/MobileMenu";
import Logo from "../../images/logo.png";

const Header = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header id="header" className={props.topbarNone}>
      {/* <HeaderTopbar /> */}
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="navbar-header">
                  <Link className="navbar-brand" to="/#home">
                    <img
                      style={{
                        height: "120px",
                        width: "250px",
                        paddingTop: 10,
                        objectFit: "cover",
                      }}
                      src={Logo}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-9 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                  style={{ justifyContent: "flex-end" }}
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/#service">Packages</Link>
                    </li>
                    <li>
                      <Link to="/#gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/#contact">Contact Us</Link>
                    </li>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href="tel:0297272127"
                        style={{
                          backgroundColor: "#006266",
                          color: "#fff",
                          cursor: "pointer",
                          textDecoration: "none",
                          fontWeight: "bold",
                          padding: 10,
                          paddingRight: 30,
                          paddingLeft: 30,
                          borderRadius: 100,
                        }}
                      >
                        0297 272 127
                      </a>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
