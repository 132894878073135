import React from "react";
import SectionTitle from "../SectionTitle";
import Services from "../../api/service";

const ServiceSection = (props) => {
  return (
    <section className="wpo-service-section section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-5">
            <SectionTitle MainTitle={"THE BATHROOM RENOVATION PROCESS"} />
          </div>
        </div>
        <div className="row">
          {Services.map((service, sitem) => (
            <div className="col-lg-4 col-md-6 col-12" key={sitem}>
              <div className="wpo-service-item">
                <div className="wpo-service-img">
                  <img src={service.sImg} alt="" />
                </div>
                <div className="wpo-service-text">
                  <h3
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: sitem === 5 ? "1.60rem" : null,
                    }}
                  >
                    {service.sTitle}
                  </h3>
                  {/* <p>{service.description}</p> */}
                  {/* <Link
                    onClick={ClickHandler}
                    to={`/service-single/${service.Id}`}
                  >
                    READ MORE{" "}
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </Link> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
