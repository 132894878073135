import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (
    <footer className="wpo-site-footer" style={{ backgroundColor: "#006266" }}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div
            className="row"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div
                className="widget about-widget"
                style={{
                  display: "flex",
                  maxWidth: "300px",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="logo widget-title"
                  style={{ maxWidth: "300px" }}
                >
                  <img
                    src={Logo}
                    style={{
                      objectFit: "cover",
                      width: "300px",
                      height: "150px",
                    }}
                    alt="logo"
                  />
                </div>

                <ul>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      target="_blank"
                      to="https://www.facebook.com/profile.php?id=61551525666097"
                    >
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      target="_blank"
                      to="https://www.instagram.com/idealbathroomsandkitchens"
                    >
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact </h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i>Sydney, New South
                      Wales, Australia
                    </li>
                    <li>
                      <a
                        href="tel:0297272127"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <i className="fi flaticon-phone-call"></i>0297 272 127
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:info@idealbathroomsandkitchens.com.au"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <i className="fi flaticon-send"></i>
                        info@idealbathroomsandkitchens.com.au
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
