import React from "react";
import Bathrooms, { Laundries, Gallery } from "../../api/project";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const ProjectSection = ({ title, laundry = false, gallery }) => {
  const data = laundry ? Laundries : gallery ? Gallery : Bathrooms;
  return (
    <section className="wpo-projects section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6">
            <SectionTitle MainTitle={title} />
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div
              className="projects-grids gallery-container clearfix"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {data.map((project, prj) => (
                <div className="grid" key={prj}>
                  <div className="project-inner" style={{ height: "100%" }}>
                    <div className="img-holder" style={{ height: "100%" }}>
                      <img
                        className="img-responsive"
                        style={{
                          objectFit: "cover",
                          height: gallery ? 300 : "100%",
                        }}
                        src={project.pImg}
                        alt=""
                      />
                    </div>
                    <div
                      className={!gallery ? "hover-content" : ""}
                      style={{ height: "100%" }}
                    >
                      <div
                        className="details"
                        style={{
                          height: "100%",
                          cursor: "pointer",
                          fontSize: 40,
                          position: "absolute",
                          top: 10,
                        }}
                      >
                        <h3 style={{ justifyContent: "space-between" }}>
                          <a
                            style={{
                              height: "100%",
                              cursor: "pointer",
                              fontSize: 40,
                              position: "absolute",
                              top: 20,
                            }}
                          >
                            {project.title}
                          </a>
                          <a
                            style={{
                              height: "100%",
                              cursor: "pointer",
                              fontSize: 20,
                              fontWeight: "lighter",
                              position: "absolute",
                              top: 20,
                              right: 20,
                            }}
                          >
                            {project.price}
                          </a>
                        </h3>
                        <br />
                        <p
                          style={
                            gallery
                              ? {
                                  textAlign: "right",
                                  bottom: 20,
                                  position: "absolute",
                                  right: 30,
                                  fontSize: 14,
                                }
                              : {
                                  paddingLeft: 10,
                                  position: "absolute",
                                  bottom: 50,
                                  left: 30,
                                }
                          }
                        >
                          {project?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {!laundry && !gallery && (
              <div
                className="wpo-section-title"
                style={{
                  paddingTop: 10,
                  paddingBottom: 0,
                  margin: 0,
                  width: "100%",
                }}
              >
                <h7>
                  The prices provided are just estimates; actual costs depend on
                  factors such as the specific property, site access, and job
                  needs. Final prices can vary for each job.
                </h7>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
