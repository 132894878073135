import React from "react";
import "../css/StickyFooter.css"; // Create a CSS file to style the sticky footer
import { Link } from "react-router-dom";

const StickyFooter = () => {
  return (
    <div className="sticky-footer">
      <Link className="get-quote-button" to="/#contact">
        Get Quote
      </Link>
      <a className="phone-number-link" href="tel:0297272127">
        0297 272 127
      </a>
    </div>
  );
};

export default StickyFooter;
