import final from "../images/service/final.jpg";
import installation from "../images/service/installation.jpg";
import consultation from "../images/service/consultation.jpg";
import plumbing from "../images/service/plumbing.jpg";
import demolition from "../images/service/demolition.jpg";
import tiling from "../images/service/tiling.jpg";

const Services = [
  {
    Id: "1",
    sImg: consultation,
    sTitle: "Consultation & Planning",
  },
  {
    Id: "2",
    sImg: demolition,
    sTitle: "Demolition & Removal",
    description: "xxxx",
  },
  {
    Id: "3",
    sImg: plumbing,
    sTitle: "Plumbing & Electrical",
    description: "xxxx",
  },
  {
    Id: "4",
    sImg: tiling,
    sTitle: "Waterproofing & Tiling",
    description: "xxxx",
  },
  {
    Id: "5",
    sImg: installation,
    sTitle: "Installation of Fixtures",
    description: "xxxx",
  },
  {
    Id: "6",
    sImg: final,
    sTitle: "Inspection & Final Touches",
    description: "xxxx",
  },
];
export default Services;
