import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from "../../images/slider/slider-1.jpg";
import hero2 from "../../images/slider/slider-2.jpg";

const settings = {
  dots: false,
  arrows: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  fade: true,
};

const Hero2 = () => {
  return (
    <section id="_slider" className="wpo-hero-slider">
      <div className="hero-container">
        <div className="hero-wrapper">
          <Slider {...settings}>
            <div className="hero-slide">
              <div
                className="slide-inner slide-bg-image"
                style={{
                  backgroundImage: `url(${hero1})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
              >
                <div className="gradient-overlay"></div>
                <div className="container">
                  <div className="slide-content">
                    <div className="slide-title">
                      <h2>
                        WHERE IDEAL
                        <br />
                        MEETS REALITY
                      </h2>
                    </div>
                    <div className="slide-text">
                      <p>
                        We are a certified company. We provide the best
                        renovation services for your home or work office
                        bathroom and kitchen.
                      </p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="slide-btns">
                      <Link to="#contact" className="theme-btn">
                        FREE QUOTE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-slide">
              <div
                className="slide-inner slide-bg-image"
                style={{
                  backgroundImage: `url(${hero2})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
              >
                <div className="gradient-overlay"></div>
                <div className="container">
                  <div className="slide-content">
                    <div className="slide-title">
                      <h2>
                        TRANSFORMING BATHROOMS TO THEIR IDEAL STATE EFFORTLESSLY
                      </h2>
                    </div>

                    <div className="clearfix"></div>
                    <div className="slide-btns">
                      <Link to="#contact" className="theme-btn">
                        FREE QUOTE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Hero2;
