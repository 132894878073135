import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from "../SectionTitle";

const settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const testimonial = [
  {
    Des: "Choosing Ideal Bathroom Renovations was the best decision I made for my bathroom remodel. They delivered on time, within budget, and the end result was absolutely beautiful. Thank you!",
    Title: "Lisa R.",
    Sub: "",
  },
  {
    Des: "From start to finish, Ideal Bathroom Renovations provided top-notch service. They listened to my ideas, offered great design suggestions, and executed the project flawlessly. Love my new bathroom!",
    Title: "Emily S.",
    Sub: "",
  },
  {
    Des: "I couldn't be happier with the results of my bathroom renovation by Ideal Bathroom Renovations. The team was professional, efficient, and the end result exceeded my expectations!",
    Title: "Mark T.",
    Sub: "",
  },
  {
    Des: "Thrilled with my bathroom renovation by Ideal Bathroom Renovations. They exceeded my expectations with their craftsmanship and attention to detail. Thank you!",
    Title: "Michael C.",
    Sub: "",
  },
  {
    Des: "The attention to detail and craftsmanship was exceptional. Highly recommend!",
    Title: "Sarah M.",
    Sub: "",
  },
];

const Testimonial = () => {
  return (
    <section className="wpo-testimonials-section section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6">
            <SectionTitle MainTitle={"What People’s Say"} />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xl-12 col-lg-12">
            <div className="testimonials-wrapper owl-carousel">
              <Slider {...settings}>
                {testimonial.map((tesmnl, tsm) => (
                  <div className="testimonials-item" key={tsm}>
                    <div className="testimonials-item-top">
                      <p>{tesmnl.Des}</p>
                    </div>
                    <div className="testimonials-item-bottom">
                      <div className="testimonials-item-bottom-author-text">
                        <h3>{tesmnl.Title}</h3>
                        <span>{tesmnl.Sub}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
